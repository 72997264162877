import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { paymentHistory } from "app/api"
import { dataStages } from "app/helpers/enum"

const { LOADING, DATA, ERROR } = dataStages

export type PaymentHistoryType = {
  amount: number
  currency: string
  transactionType: string
  providerName: string
  referenceNumber: string
  status: string
  id: string
  userId: string
  createdDate: Date
  lastModifiedDate: Date
  fromUsername: string
}

export type PaymentHistoryInitialState = {
  paymentHistory: {
    payments: {
      pages: {
        [key: number]: PaymentHistoryType[]
      }
      totalRecords: number
    }
    paymentsParams: {
      PageNumber: number
      PageSize: number
    }
    searchValue: string
    stage: dataStages
  }
}

export const initialState: PaymentHistoryInitialState = {
  paymentHistory: {
    payments: {
      pages: {},
      totalRecords: 0,
    },
    paymentsParams: {
      PageNumber: 1,
      PageSize: 10,
    },
    searchValue: "",
    stage: LOADING,
  },
}

export const paymentHistoryRequest = createAsyncThunk<
  {
    data: PaymentHistoryInitialState["paymentHistory"]["payments"]["pages"]
    totalRecords: PaymentHistoryInitialState["paymentHistory"]["payments"]["totalRecords"]
  },
  { PageNumber: number; PageSize: number; searchValue?: string }
>(
  "payment-history",
  async ({ PageNumber, PageSize, searchValue }, { rejectWithValue }) => {
    try {
      const response = await paymentHistory.getPaymentHistory(
        PageNumber,
        PageSize,
      )

      return {
        data: { [PageNumber]: response.data },
        totalRecords: response.totalCount,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const paymentHistorySlice = createSlice({
  name: "payment-history",
  initialState,
  reducers: {
    setPaymentHistoryParams: (state, { payload }) => {
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          paymentsParams: {
            ...state.paymentHistory.paymentsParams,
            PageNumber: payload,
          },
        },
      }
    },
    setSearchValue: (state, { payload }) => {
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          searchValue: payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(paymentHistoryRequest.pending, (state) => {
      state.paymentHistory.stage = LOADING
    })
    builder.addCase(paymentHistoryRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        paymentHistory: {
          ...state.paymentHistory,
          payments: {
            pages: {
              ...state.paymentHistory.payments.pages,
              ...payload.data,
            },
            totalRecords: payload.totalRecords,
          },
          stage: DATA,
        },
      }
    })
    builder.addCase(paymentHistoryRequest.rejected, (state) => {
      state.paymentHistory.stage = ERROR
    })
  },
})

export const { setPaymentHistoryParams, setSearchValue } =
  paymentHistorySlice.actions

export default paymentHistorySlice.reducer
