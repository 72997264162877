import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { users } from "app/api"
import { toast } from "react-hot-toast"
import { dataStages } from "app/helpers/enum"

const { LOADING, DATA, ERROR } = dataStages

export type UserProfileType = {
  userId: string
  firstName: string
  lastName: string
  dateOfBirth: Date
  gender: number
  languageCSV: string
  aboutMe: string
  bodyType: string
  complexion: string
  height: number
  occupation: string
  religion: string
  weight: number
  tagline: string
  city: string
  state: string
  country: string
  zipCode: string
  latitude: number
  longitude: number
  maritalStatus: number
}

export type RolesType = {
  adminManagersCount: number
  adminStaffsCount: number
  customersCount: number
}

type Address = {
  city: string
  country: string
  state: string
  zipCode: string
}

export type UsersType = {
  createdDate: Date
  firstName: string
  isFullyVerified: boolean
  lastName: string
  residentialAddress: Address
  rolesCSV: string
  userId: string
  isBlocked: boolean
  email: string
  phoneNumber: string
  userName: string
  subscriptionPlan: string
}

export type UsersInitialState = {
  users: {
    usersList: {
      pages: {
        [key: number]: UsersType[]
      }
      totalRecords: number
    }
    usersParams: {
      PageNumber: number
      PageSize: number
    }
    searchValue: string
    stage: dataStages
  }
  usersCount: {
    count: number
    stage: dataStages
  }
  singleUser: {
    user: {
      location: {
        latitude: number
        longitude: number
      }
      profile: {
        id: string
        age: number
        aboutMe: string
        bodyType: string
        complexion: string
        dateOfBirth: Date
        firstName: string
        gender: string
        height: number
        languageCSV: string
        lastName: string
        occupation: string
        profilePhotoURL: string | null
        profileStatus: number
        maritalStatus: number
        religion: string
        tagline: string
        weight: number
        lastSeenTime: string
        isOnline: boolean
        lastModifiedDate: string
        createdDate: string
        user: {
          createdDate: Date
          emailConfirmed: boolean
          isFullyVerified: boolean
          lastSeenDate: Date
          normalizedEmail: string
          phoneCountryCode: string
          phoneNumber: string
          phoneNumberConfirmed: boolean
          rolesCSV: string
          userId: string
          userName: string
        }
        userSubscription: {
          subscriptionId: number
          name: string
          amount: number
          startdate: Date
          enddate: Date
          status: number
          paymentmethod: string
          subscription: {
            id: number
            name: string
            description: string
            fee: number
            allowMessaging: boolean
            noMatchesPerDay: number
            noOfBuddyEventPerWeek: number
            noNearbyPerWeek: number
            allowWithdrawal: boolean
            allowMultipleMediaInPost: boolean
            maxNumberOfMediaInPost: number
            allowVideoMediaCategoryInPost: boolean
            allowCreateCommunity: boolean
            accessPeopleNearby: boolean
          }
        }
        wallet: {
          availableBalance: number
          lastTransactionDate: Date
        }
      }
      profileCounters: {
        friendsCount: number
        postCount: number
        refereesCount: number
      }
      residentialAddress: Address
      subscription: {
        amount: number
        enddate: Date
        name: string
        paymentmethod: string
        startdate: Date
        status: number
        subscriptionId: number
      }
    }
    stage: dataStages
  }
}

export const initialState: UsersInitialState = {
  users: {
    usersList: {
      pages: {},
      totalRecords: 0,
    },
    usersParams: {
      PageNumber: 1,
      PageSize: 10,
    },
    searchValue: "",
    stage: LOADING,
  },
  usersCount: {
    count: 0,
    stage: LOADING,
  },
  singleUser: {
    user: {} as UsersInitialState["singleUser"]["user"],
    stage: LOADING,
  },
}

export const usersRequest = createAsyncThunk<
  {
    data: UsersInitialState["users"]["usersList"]["pages"]
    totalRecords: UsersInitialState["users"]["usersList"]["totalRecords"]
  },
  { PageNumber: number; PageSize: number; searchValue?: string }
>(
  "user/users",
  async ({ PageNumber, PageSize, searchValue }, { rejectWithValue }) => {
    try {
      const response = searchValue
        ? await users.usersSearch(PageNumber, PageSize, searchValue)
        : await users.getUsers(PageNumber, PageSize)

      return {
        data: { [PageNumber]: response.data },
        totalRecords: response.totalCount,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const singleUserRequest = createAsyncThunk<
  UsersInitialState["singleUser"]["user"],
  string
>("user/single-user", async (userId, { rejectWithValue }) => {
  try {
    const response = await users.getSingleUser(userId)

    return response.data
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const rolesCountRequest = createAsyncThunk<RolesType, void>(
  "user/users-count",
  async (_, { rejectWithValue }) => {
    try {
      const response = await users.getRolesCount()

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const enableUserRequest = createAsyncThunk<"", string>(
  "user/enable-user",
  async (userId, { rejectWithValue }) => {
    try {
      await users.enableUser(userId)

      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const disableUserRequest = createAsyncThunk<"", string>(
  "user/disable-user",
  async (userId, { rejectWithValue }) => {
    try {
      await users.disableUser(userId)

      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateUserRequest = createAsyncThunk<"", UserProfileType>(
  "users/update-profile",
  async (values, { rejectWithValue }) => {
    try {
      await users.updateUserProfile(values)

      toast.success("User updated successfully")
      return ""
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersParams: (state, { payload }) => {
      return {
        ...state,
        users: {
          ...state.users,
          usersParams: {
            ...state.users.usersParams,
            PageNumber: payload,
          },
        },
      }
    },
    setSearchValue: (state, { payload }) => {
      return {
        ...state,
        users: {
          ...state.users,
          searchValue: payload,
        },
      }
    },
    clearUsersList: (state) => {
      return {
        ...state,
        users: {
          ...state.users,
          searchValue: "",
          usersList: {
            ...state.users.usersList,
            pages: {},
          },
          usersParams: {
            ...state.users.usersParams,
            PageNumber: 1,
          },
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersRequest.pending, (state) => {
      state.users.stage = LOADING
    })
    builder.addCase(usersRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        users: {
          ...state.users,
          usersList: {
            pages: {
              ...state.users.usersList.pages,
              ...payload.data,
            },
            totalRecords: payload.totalRecords,
          },
          stage: DATA,
        },
      }
    })
    builder.addCase(usersRequest.rejected, (state) => {
      state.users.stage = ERROR
    })
    builder.addCase(singleUserRequest.pending, (state) => {
      state.singleUser.stage = LOADING
    })
    builder.addCase(singleUserRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          user: payload,
          stage: DATA,
        },
      }
    })
    builder.addCase(singleUserRequest.rejected, (state) => {
      state.singleUser.stage = ERROR
    })
    builder.addCase(rolesCountRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        usersCount: {
          ...state.usersCount,
          count: payload?.customersCount,
          stage: DATA,
        },
      }
    })
    builder.addCase(rolesCountRequest.rejected, (state) => {
      state.usersCount.stage = ERROR
    })
  },
})

export const { setUsersParams, setSearchValue, clearUsersList } =
  usersSlice.actions

export default usersSlice.reducer
