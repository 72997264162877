import {
  configureStore,
  combineReducers,
  Action,
  CombinedState,
} from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import storage from "redux-persist/lib/storage"
import { persistStore, persistReducer } from "redux-persist"
import auth, { AuthInitialState } from "features/Auth/AuthSlice"
import staffs, { StaffsInitialState } from "features/Staffs/StaffsSlice"
import insight, { InsightInitialState } from "features/Insight/InsightSlice"
import settings, { SettingsInitialState } from "features/Settings/SettingsSlice"
import subFeatures, {
  SubFeaturesInitialState,
} from "features/SubscriptionFeatures/SubscriptionFeaturesSlice"
import users, { UsersInitialState } from "features/Users/UsersSlice"
import paymentHistory, {
  PaymentHistoryInitialState,
} from "features/PaymentStaging/PaymentStagingSlice"
import transactions, {
  TransactionsInitialState,
} from "features/Transactions/TransactionsSlice"
import subscriptions, {
  SubscriptionsInitialState,
} from "features/Subscriptions/SubscriptionsSlice"
import profileBoost, {
  ProfileBoostInitialState,
} from "features/ProfileBoost/ProfileBoostSlice"
import gifts, { GiftsInitialState } from "features/GiftsManagement/GiftSlice"

export const appReducer = combineReducers({
  auth,
  staffs,
  insight,
  settings,
  subFeatures,
  users,
  paymentHistory,
  transactions,
  subscriptions,
  profileBoost,
  gifts,
})

export type RootState = {
  auth: AuthInitialState
  staffs: StaffsInitialState
  insight: InsightInitialState
  settings: SettingsInitialState
  subFeatures: SubFeaturesInitialState
  users: UsersInitialState
  paymentHistory: PaymentHistoryInitialState
  transactions: TransactionsInitialState
  subscriptions: SubscriptionsInitialState
  profileBoost: ProfileBoostInitialState
  gifts: GiftsInitialState
}

export const rootReducer = (
  state: CombinedState<RootState> | undefined,
  action: Action,
) => {
  if (action.type === "auth/logOut") {
    localStorage.removeItem("persist:root")
    storage.removeItem("persist:root")
    state = undefined
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "auth",
    "staffs",
    "insight",
    "settings",
    "subFeatures",
    "users",
    "paymentHistory",
    "transactions",
    "subscriptions",
    "profileBoost",
    "gifts",
  ],
}

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
