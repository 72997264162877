import { Navigate, RouterProvider } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import router from "app/route"
import "./App.scss"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { useState } from "react"

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 3,
            refetchOnWindowFocus: true,
          },
        },
      }),
  )
  let isLoggedIn: boolean = true

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />
  }

  return (
    <div className="app">
      <Toaster position="top-right" reverseOrder={false} />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  )
}

export default App
